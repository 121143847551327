<template>
  <div class="admin__buttons">
<!--    Этот компонент сохраняет данные в store, либо запрашивает данные с сервера и заменяет на странице,
чтобы не нужно было перезгружать страницу. Когда будет полностью сделан бэк в этом компоненте не будет необходимости
Подключать как
<admin-buttons-block v-if="checkContentManagerStatus" :options="adminButtonsOptions" />
-->
    <button class="admin__button" type="button" @click="sendToServer">Сохранить изменения</button>
    <button class="admin__button" type="button" @click="fetchDataFromServer">Отменить изменения</button>
    <slot name="activeBtn"></slot>

  </div>
</template>

<script>


export default {
  name: "AdminButtonsBlock",
  props: {
    options: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    sendToServer() {
      this.$store.dispatch(this.options.actionNameSave, {path: this.$route.path.slice(1)})
    },
    fetchDataFromServer() {
      const accept = confirm("Вы точно хотите отменить изменения на этой странице?")
      if (accept) {
        this.$store.dispatch(this.options.actionNameCancel)
      }
    },
    }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";
  .admin {
    &__buttons {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 30px;
    }

    &__button {
      padding: 10px 15px;
      font-size: $fontSizeL;
      background-color: #E7E7E8;
      border-radius: 10px;
      cursor: pointer;

      &:hover {
        color: $white-color;
        background-color: $primary-color;
      }
    }
  }
</style>
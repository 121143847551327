<!--Подлючаем в компонент через
    <admin-widget-add v-if="checkContentManagerStatus" :options="widgetOptions"></admin-widget-add>
В пропс передаем объект
    widgetOptions: {
        title: заголовок виджета,
        mutationName: название мутации в STORE,
        inputs: массив параметров инпутов
          [{
            label: название поля,
            numberOfRows: количество строк TEXTAREA,
            vModelKeyName: название ключа в объекте для v-model,
            editorEnable: true, если нужно использовать editor вместо textarea
            selectEnable: true, если нужно использовать select вместо textarea
            selectType: 'contacts' || 'components' || 'cardImages' выбирает нужные элементы, заполняются из computed-свойств
          }],
          parentId: id родителя (необязательно),
          sectionId: id секции (необязательно, нужно чтобы ускорить поиск по объектам)
      }

Изменения сохраняются сначала в store, а потом после клика на кнопку Сохранить изменения в admin-buttons-block отправляются на бэк-->
<template>
  <div class="widget">
    <div class="widget-title">{{ options.title }}</div>
    <div v-if="getCurrentPath === '/contacts'" class="widget-radio">
      <label>
        <input
          v-model="contactTypeId"
          class="widget-radio-item"
          name="contact"
          type="radio"
          value="0"
        />Телефон
      </label>
      <label>
        <input
          v-model="contactTypeId"
          class="widget-radio-item"
          name="contact"
          type="radio"
          value="1"
        />Email
      </label>
    </div>
    <div
      v-for="(input, index) in options.inputs"
      :key="index"
      class="widget-input"
    >
      <label v-if="showInput" class="widget-input-label">{{ input.label }}</label>
      <textarea
        v-if="showInput && !input.editorEnable && !input.selectEnable && !input.datePicker"
        v-model="newObj[input.vModelKeyName]"
        :rows="input.numberOfRows"
        class="widget-input-textarea"
      ></textarea>
      <select
        v-if="showInput && input.selectEnable && input.selectItems"
        v-model="newObj[input.vModelKeyName]"
        class="widget-input-select"
      >
        <label>{{ input.label }}</label>
        <option v-for="(item, index) in input.selectItems" :key="index">{{ item }}</option>
      </select>
      <select
        v-if="showInput && input.selectEnable && input.selectType !== 'cardImages'  && !input.selectItems"
        v-model="newObj[input.vModelKeyName]"
        class="widget-input-select"
      >
        <label>{{ input.label }}</label>
        <option v-for="(item, index) in getSelectItems" :key="index">{{ item }}</option>
      </select>
      <select
        v-if="showInput && input.selectType === 'cardImages' && !input.selectItems"
        v-model="newObj[input.vModelKeyName]"
        class="widget-input-select">
        <label>{{ input.label }}</label>
        <option v-for="(item, index) in getCardImageList" :key="index">{{ item }}</option>
      </select>
      <editor
        v-if="showInput && input.editorEnable && !input.selectEnable"
        v-model="newObj[input.vModelKeyName]"
        :apiKey="getEditorKey"
        :init="getEditorOptions"
        class="widget-input-editor"
      />
      <date-picker
        :lang="datePickerOptions.lang"
        :format="datePickerOptions.format"
        :value-type="datePickerOptions.valueType"
        v-if="showInput && !input.editorEnable && !input.selectEnable && input.datePicker"
        v-model="newObj[input.vModelKeyName]"
        class="widget-input-datepicker"
        type="datetime"
      />
    </div>
    <div>
      <button class="widget-button" type="button" @click="addItem">
        Добавить
      </button>
    </div>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "AdminWidgetAdd",
  components: {
    Editor,
    DatePicker
  },
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    modalId: {
      type: Number
    }
  },
  data() {
    return {
      newObj: {},
      contactTypeId: "",
      datePickerOptions: {
        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
          },
          monthBeforeYear: false,
        },
        format: 'DD.MM.YYYY HH:mm',
        valueType: 'timestamp'
      }
    };
  },
  computed: {
    getCurrentPath() {
      return this.$route.path;
    },
    showInput() {
      if (this.getCurrentPath === "/contacts") {
        return !!this.contactTypeId;
      }
      return true;
    },
    getSelectItems() {
      if (this.getCurrentPath === "/contacts") {
        return ["vk", "whatsapp", "telegram", "odnoklassniki"];
      } else {
        return this.$store.getters.getComponentTypes;
      }
    },
    getCardImageList() {
      return this.$store.getters.getCardImages;
    },
    getEditorOptions() {
      let editorOptions = this.$store.getters.getEditorOptions;
      if (this.options.editorWidth) {
        editorOptions.width = this.options.editorWidth;
      }
      return editorOptions;
    },
    getEditorKey() {
      return this.$store.getters.getEditorKey;
    }
  },
  methods: {
    addItem() {
      let payload = {
        currentPath: this.$route.path,
        newObj: this.newObj
      };
      if (this.options.parentId !== undefined) {
        payload["parentId"] = this.options.parentId;
      }
      if (this.contactTypeId) {
        payload["contactTypeId"] = this.contactTypeId;
      }
      if (this.options.sectionId !== undefined) {
        payload["sectionId"] = this.options.sectionId;
      }
      if (this.modalId !== undefined) {
        payload["modalId"] = this.modalId;
      }
      this.$store.commit(this.options.mutationName, payload);
      this.newObj = {};
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.widget {
  width: 100%;
  margin-bottom: 50px;

  &-title {
    margin-bottom: 10px;
    font-size: $fontSizeL;
    text-align: center;
  }

  &-input {
    margin-bottom: 10px;
    padding: 0 95px;

    > label {
      font-weight: 700;
    }

    > select {
      width: 100%;
      padding: 10px;
      border: 1px solid $black-color;

      > option {
        padding: 10px;
      }
    }

    > textarea {
      width: 100%;
      display: block;
      padding: 10px;
      border: 1px solid $black-color;
      resize: vertical;

      &:focus {
        background-color: $white-color;
      }
    }

    &-datepicker {
      width: 400px;
      margin-bottom: 20px;
      padding-left: 40px;
    }
  }

  &-radio {
    margin-bottom: 20px;

    > label {
      margin-right: 30px;
      font-weight: 700;
      cursor: pointer;
    }

    &-item {
      width: 15px;
      height: 15px;
      margin-right: 10px;
      background-color: $white-color;
      border: 1px solid $black-color;
      border-radius: 50%;

      &:checked {
        background-color: $primary-color;
      }
    }
  }

  &-button {
    display: block;
    margin: 0 auto;
    padding: 10px 15px;
    font-size: $fontSizeL;
    background-color: #e7e7e8;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      color: $white-color;
      background-color: $primary-color;
    }
  }
}
</style>